import React from 'react';
import Navbar from './Navbar';
import { useParams } from 'react-router-dom';
import '../css/style.css';
import { useState, useEffect } from 'react';
import FeaturedCategories from './FeaturedCategories';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';

const CategoriesPage = () => {

    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`http://api.fitsgames.com/api/categories`)
            .then(response => response.json())
            .then(data => setCategories(data)) // Display only the first 4 categories
            .catch(error => console.error('Error fetching categories:', error));
    }, []);

    const bannerImages = [
        'images/banner-1-fits.jpg',
        'images/banner-2-fits.jpg',
        'images/banner-3-fits.jpg',
        'images/banner-4-fits.jpg',
        'images/banner-5-fits.jpg'
    ];

    const handleCategoriesRedirect = (id, name) => {
        navigate(`/games/${id}/${name}`);
    };

    return (
        <>
            <Navbar />
            <div className="hero-wrap js-fullheight" style={{ backgroundImage: 'url(/images/banner-1-fits.jpg)', height: 600 }}>
                <div className="overlay"></div>
                <div className="container">
                    <div className="row no-gutters slider-text js-fullheight" data-scrollax-parent="true" style={{ height: "100vh", display: "flex", alignItems: "center" }}>
                        <h1 className="mb-4" data-scrollax="properties: { translateY: '70%', opacity: 1.6 }">
                            {/* <strong>Explore<br /></strong> our amazing content */}
                        </h1>
                        <p data-scrollax="properties: { translateY: '30%', opacity: 1.6 }" style={{ textAlign: 'center', marginTop: '70px' }}>
                            <h1 className="mb-4" data-scrollax="properties: { translateY: '70%', opacity: 1.6 }"><strong>Explore<br />Game</strong><br /><strong>Categories!</strong></h1>
                        </p>
                        <p>
                            {/* Or browse our games by categories */}
                        </p>
                    </div>
                </div>
            </div >

            <section className="ftco-section ftco-destination">
                <div className="container">
                    <span className="subheading">Featured</span>
                    <h2 className="mb-4"><strong>Game</strong> Categories</h2>
                    <div className="row">


                        {categories.map((category, index) => (
                            <div className="col-md-3" key={category.id}>
                                <div className="item">
                                    <div className="destination">
                                        <div class="text p-3">
                                            <a onClick={()=>handleCategoriesRedirect(category.id, category.cat_name)} className="img d-flex justify-content-center align-items-center"
                                                style={{ backgroundImage: `url(${bannerImages[index % bannerImages.length]})`, height: '300px', backgroundSize: 'cover' }}>
                                                <div className="icon d-flex justify-content-center align-items-center">
                                                    <span className="icon-search2"></span>
                                                </div>
                                            </a>
                                            <div className="text p-3">
                                                <h3><a onClick={()=>handleCategoriesRedirect(category.id, category.cat_name)} style={{ textDecoration: 'none' }}>{category.cat_name}</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
};

export default CategoriesPage;
