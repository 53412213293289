import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../css/FeaturedCategories.css';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSkullCrossbones, faPuzzlePiece, faGamepad, faCar, faLightbulb, faGraduationCap, faFutbol, faUser, faMapMarkerAlt, faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const FeaturedCategories = () => {
    const [categories, setCategories] = useState([]);
    const navigate=useNavigate();

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 980,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,  // Hide arrows on mobile
                    dots: false,    // Hide dots on mobile
                }
            }
        ]
    };

    useEffect(() => {
        fetch('http://api.fitsgames.com/api/categories')
            .then(response => response.json())
            .then(data => setCategories(data))
            .catch(error => console.error('Error fetching categories:', error));
    }, []);

    const getCategoryIcon = (catName) => {
        const iconMap = {
            'Arcade': faGamepad,
            'Puzzle': faPuzzlePiece,
            'IQ': faLightbulb,
            'Shooting': faSkullCrossbones,
            'Adventure': faMapMarkerAlt,
            'Educational': faGraduationCap,
            'Racing': faCar,
            'Strategy': faLightbulb,
            'Physics': faUser,
            'Sports': faFutbol,
            'Leisure': faShoppingBag,
            'Casual': faUser
        };
        return iconMap[catName] || faGamepad;
    };

    const bannerImages = [
        'images/banner-1-fits.jpg',
        'images/banner-2-fits.jpg',
        'images/banner-3-fits.jpg',
        'images/banner-4-fits.jpg',
        'images/banner-5-fits.jpg'
    ];

    const handleCategoriesRedirect = (id, name) => {
        navigate(`/games/${id}/${name}`);
    };

    return (
        <section className="ftco-section ftco-destination">
            <div className="container">
                <div className="row justify-content-start mb-5 pb-3">
                    <div className="col-md-7 heading-section ftco-animate">
                        <span className="subheading">Featured</span>
                        <h2 className="mb-4"><strong>Game</strong> Categories</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Slider {...settings} className="destination-slider owl-carousel ftco-animate">
                            {categories.map((category, index) => (
                                <div className="item" key={category.id}>
                                    <div className="destination">
                                        <a onClick={() => handleCategoriesRedirect(category.id, category.cat_name)} className="img d-flex justify-content-center align-items-center"
                                            style={{ backgroundImage: `url(${bannerImages[index % bannerImages.length]})`, height: '300px', backgroundSize: 'cover' }}>
                                            <div className="icon d-flex justify-content-center align-items-center">
                                                <span className="icon-search2"></span>
                                            </div>
                                        </a>
                                        <div className="text p-3">
                                            <h3><a onClick={() => handleCategoriesRedirect(category.id, category.cat_name)} style={{ textDecoration: 'none' }}>{category.cat_name}</a></h3>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    );
};

const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: 'block' }}
            onClick={onClick}
        >
            <FontAwesomeIcon icon={faChevronRight} style={{ color: 'white' }} />
        </div>
    );
};

const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: 'block' }}
            onClick={onClick}
        >
            <FontAwesomeIcon icon={faChevronLeft} style={{ color: 'white' }} />
        </div>
    );
};

export default FeaturedCategories;
