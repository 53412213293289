import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import LOGO from '../images/logo.png';

const Navbar = () => {
    const [phoneNumber, setPhoneNumber] = useState(sessionStorage.getItem('phoneNumber'));
    const [loginText, setLoginText] = useState('LogIn');
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (phoneNumber) {
            setLoginText('LogOut');
        } else {
            setLoginText('LogIn');
        }
    }, [phoneNumber]);

    const handleLogout = () => {
        setShowModal(true);
    };

    const handleLogoutConfirm = () => {
        sessionStorage.removeItem('phoneNumber');
        setPhoneNumber(null);
        setShowModal(false);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleLoginRedirect = () => {
        navigate('/login');
    };

    const handleCategoriesRedirect = () => {
        navigate('/categories');
    };

    const handleGamesRedirect = () => {
        navigate('/games');
    };

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar" style={{position:'sticky', top: 0}}>
                <div className="container">
                    <a className="navbar-brand" href="/">
                        <img src={LOGO} alt="Logo" style={{ height: '60px' }} />
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav"
                        aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="oi oi-menu"></span> Menu
                    </button>

                    <div className="collapse navbar-collapse" id="ftco-nav">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item active"><a href="/" className="nav-link">Home</a></li>
                            <li className="nav-item"><a onClick={handleCategoriesRedirect} className="nav-link">Categories</a></li>
                            <li className="nav-item"><a onClick={handleGamesRedirect} className="nav-link">Games</a></li>
                            <li className="nav-item cta">
                                {loginText === 'LogOut' ? (
                                    <a className="nav-link" onClick={handleLogout}><span>{loginText}</span></a>
                                ) : (
                                    <a className="nav-link" onClick={handleLoginRedirect}><span>{loginText}</span></a>
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Logout</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to logout?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleLogoutConfirm}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Navbar;
