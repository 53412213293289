import React from 'react';
import '../css/style.css';
import LOGO from '../images/logo.png';

const Footer = () => {
    return (
        <footer className="ftco-footer ftco-bg-dark ftco-section">
            <div className="container">
                <div className="row mb-5">
                    <div className="col-md">
                        <div className="ftco-footer-widget mb-4">
                            <a className="navbar-brand" href="/">
                                <img src={LOGO} alt="Logo" style={{ height: '60px' }} />
                            </a>
                            <p>Play Unlimited Games with FitsGames.com</p>
                            <ul className="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
                                <li className="ftco-animate"><a href="facebook.com"><span className="icon-facebook"></span></a></li>
                                <li className="ftco-animate"><a href="instagram.com"><span className="icon-instagram"></span></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="ftco-footer-widget mb-4 ml-md-5">
                            <h2 className="ftco-heading-2">Information</h2>
                            <ul className="list-unstyled">
                                <li><a href="/" className="py-2 d-block">About</a></li>
                                <li><a href="/" className="py-2 d-block">Service</a></li>
                                <li><a href="/" className="py-2 d-block">Terms and Conditions</a></li>
                                <li><a href="/" className="py-2 d-block">Privacy and Policy</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="ftco-footer-widget mb-4">
                            <h2 className="ftco-heading-2">Customer Support</h2>
                            <ul className="list-unstyled">
                                <li><a href="/" className="py-2 d-block">FAQ</a></li>
                                <li><a href="/" className="py-2 d-block">Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <p>
                            Copyright &copy; <script>document.write(new Date().getFullYear());</script> All rights reserved | made with <i className="icon-heart" aria-hidden="true"></i> by <a href="/" target="_blank" rel="noopener noreferrer">FitsGames</a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
