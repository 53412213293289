import React, { useState } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [showVerificationField, setShowVerificationField] = useState(false);
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const navigate=useNavigate();
    
    const handleSendVerificationCode = () => {
        if (phoneNumber.trim() === '') {
            setError('Phone number is required.');
            return;
        }
        setError('');
        setShowVerificationField(true);
    };

    const handleLogin = () => {
        if (
            (phoneNumber === '03125250525' && verificationCode === '0000') ||
            (phoneNumber === '03145719684' && verificationCode === '0000') ||
            (phoneNumber === '03115050575' && verificationCode === '0000')||
            (phoneNumber === '923125250525' && verificationCode === '0000') ||
            (phoneNumber === '923145719684' && verificationCode === '0000') ||
            (phoneNumber === '923115050575' && verificationCode === '0000')||
            (phoneNumber === '3125250525' && verificationCode === '0000') ||
            (phoneNumber === '3145719684' && verificationCode === '0000') ||
            (phoneNumber === '3115050575' && verificationCode === '0000')
        ) {
            sessionStorage.setItem('phoneNumber', phoneNumber);
            setShowModal(true);
        } else {
            setError('Invalid phone number or verification code.');
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        navigate('/');
    };

    return (
        <>
            <Navbar />
            <section className="ftco-section contact-section ftco-degree-bg">
                <div className="container">
                    <div className="row d-flex mb-5 contact-info">
                        <div className="col-md-12 mb-4">
                            <h2 className="h4">Login To FitsGames</h2>
                        </div>
                    </div>
                    <div className="row block-9">
                        <div className="col-md-6 pr-md-5">
                            <form onSubmit={(e) => e.preventDefault()}>
                                {!showVerificationField && (
                                    <>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Phone Number"
                                                value={phoneNumber}
                                                onChange={(e) => setPhoneNumber(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <button
                                                type="button"
                                                className="btn btn-primary py-3 px-5"
                                                onClick={handleSendVerificationCode}
                                            >
                                                Send Verification Code
                                            </button>
                                        </div>
                                    </>
                                )}
                                {showVerificationField && (
                                    <>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Verification Code"
                                                value={verificationCode}
                                                onChange={(e) => setVerificationCode(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <button
                                                type="button"
                                                className="btn btn-primary py-3 px-5"
                                                onClick={handleLogin}
                                            >
                                                Login
                                            </button>
                                        </div>
                                    </>
                                )}
                                {error && <div className="form-group"><p style={{ color: 'red' }}>{error}</p></div>}
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Login Successful</Modal.Title>
                </Modal.Header>
                <Modal.Body>Welcome to FitsGames! You have successfully logged in.</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Login;
