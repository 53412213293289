import React from 'react';
import '../css/HomePage.css';
import Navbar from './Navbar';
import { useEffect, useState } from 'react';
import FeaturedCategories from './FeaturedCategories';
import FeaturedGames from './FeaturedGames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSkullCrossbones, faPuzzlePiece, faGamepad, faCar, faLightbulb, faGraduationCap, faFutbol, faUser, faMapMarkerAlt, faShoppingBag, faPhotoVideo, faPhotoFilm } from '@fortawesome/free-solid-svg-icons';
import '../css/style.css';
import Footer from './Footer';
import '../css/mobileimage.css';
import { Image } from 'react-bootstrap';
import MainBannerImage from '../images/fits-home-banner.png';

const HomePage = () => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        fetch('http://api.fitsgames.com/api/categories')
            .then(response => response.json())
            .then(data => setCategories(data.slice(0, 4))) // Display only the first 4 categories
            .catch(error => console.error('Error fetching categories:', error));
    }, []);

    const getCategoryIcon = (catName) => {
        const iconMap = {
            'Arcade': faGamepad,
            'Puzzle': faPuzzlePiece,
            'IQ': faLightbulb,
            'Shooting': faSkullCrossbones,
            'Adventure': faMapMarkerAlt,
            'Educational': faGraduationCap,
            'Racing': faCar,
            'Strategy': faLightbulb,
            'Physics': faUser,
            'Sports': faFutbol,
            'Leisure': faShoppingBag,
            'Casual': faUser
        };
        return iconMap[catName] || faGamepad;
    };

    return (
        <>
            <Navbar />
            <div className="hero-wrap-home js-fullheight">
                <Image src={MainBannerImage} className="banner-image" fluid />
            </div>

            <section className="ftco-section services-section bg-light">
                <div className="container">
                    <div className="row d-flex">
                        <div className="col-md-4 d-flex align-self-stretch ftco-animate">
                            <div className="media block-6 services d-block" style={{ alignContent: 'center', alignSelf: 'center', textAlign: 'center' }}>
                                <div className="icon"><FontAwesomeIcon icon={faPuzzlePiece} color='#dc3545'></FontAwesomeIcon></div>
                                <div className="media-body">
                                    <h3 className="heading mb-3">Casual Gaming</h3>
                                    <p>Highlighting the relaxing and unwinding aspect of playing games.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 d-flex align-self-stretch ftco-animate">
                            <div className="media block-6 services d-block" style={{ alignContent: 'center', alignSelf: 'center', textAlign: 'center' }}>
                                <div className="icon"><FontAwesomeIcon icon={faGamepad} color='#dc3545'></FontAwesomeIcon></div>
                                <div className="media-body">
                                    <h3 className="heading mb-3">Diverse Categories</h3>
                                    <p>Emphasizing the variety of game categories available.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 d-flex align-self-stretch ftco-animate">
                            <div className="media block-6 services d-block" style={{ alignContent: 'center', alignSelf: 'center', textAlign: 'center' }}>
                                <div className="icon"><FontAwesomeIcon icon={faPhotoFilm} color='#dc3545'></FontAwesomeIcon></div>
                                <div className="media-body">
                                    <h3 className="heading mb-3">Just for Fun</h3>
                                    <p>Focusing on the pure entertainment without any stress or competition.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FeaturedCategories />
            <hr></hr>
            <FeaturedGames />

            <Footer />
        </>
    );

};

export default HomePage;
