import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../css/FeaturedCategories.css'; // Import your CSS file here
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSkullCrossbones, faPuzzlePiece, faGamepad, faCar, faLightbulb, faGraduationCap, faFutbol, faUser, faMapMarkerAlt, faShoppingBag, faPhotoVideo, faPhotoFilm } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const FeaturedGames = () => {
    const [categories, setCategories] = useState([]);
    const navigate=useNavigate();
    
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024, // Breakpoint for tablets and large devices
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 980,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768, // Breakpoint for tablets and medium devices
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480, // Breakpoint for mobile devices
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    useEffect(() => {
        fetch('http://api.fitsgames.com/api/games?limit=5&start=1')
            .then(response => response.json())
            .then(data => setCategories(data)) // Display only the first 4 categories
            .catch(error => console.error('Error fetching categories:', error));
    }, []);

    const handleOpenGameClick = (path) => {
        if (sessionStorage.getItem('phoneNumber')) {
            window.location.href=path;
        } else {
            navigate('/login');
        }
    }

    return (
        <section className="ftco-section ftco-destination">
            <div className="container">
                <div className="row justify-content-start mb-5 pb-3">
                    <div className="col-md-7 heading-section ftco-animate" style={{ marginTop: '-30px' }}>
                        <span className="subheading">Top</span>
                        <h2 className="mb-4"><strong>Games</strong> Links</h2>
                    </div>
                </div>
                <div className="row" style={{ marginTop: '-50px' }}>
                    <div className="col-md-12">
                        <Slider {...settings} className="destination-slider owl-carousel ftco-animate">
                            {categories.map((category, index) => (
                                <div className="item" key={category.id}>
                                    <div className="destination">
                                        <a className="img d-flex justify-content-center align-items-center"
                                            style={{ backgroundImage: `url(${category.thumbnail})`, height: '300px', backgroundSize: 'cover' }}
                                            onClick={() => handleOpenGameClick(category.path)}>
                                            <div className="icon d-flex justify-content-center align-items-center">
                                                <span className="icon-search2"></span>
                                            </div>
                                        </a>
                                        <div className="text p-3">
                                            <h3><a style={{ textDecoration: 'none' }}
                                                onClick={() => handleOpenGameClick(category.path)}
                                            >{category.title}</a></h3>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    );
};

const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: 'block', background: '#dc3545', color: '#fff', borderRadius: '50%', padding: '10px' }}
            onClick={onClick}
        >
            <FontAwesomeIcon icon={faChevronRight} style={{ color: 'white' }} />
        </div>
    );
};

const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: 'block', background: '#dc3545', color: '#fff', borderRadius: '50%', padding: '10px' }}
            onClick={onClick}
        >
            <FontAwesomeIcon icon={faChevronLeft} style={{ color: 'white' }} />
        </div>
    );
};

export default FeaturedGames;
