import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import './css/style.css';
import HomePage from "./components/Home";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import CategoryBasedGame from './components/CategoryBasedGame';
import CategoriesPage from './components/CategoriesPage';
import Games from './components/Games';
import Login from './components/Login';
export default function App() {
  return (
    <>
      <Routes>
        <Route exact path='/' element={<HomePage />} />
        <Route path='/games/:categoryid/:categoryname' element={<CategoryBasedGame />} />
        <Route path='/categories' element={<CategoriesPage />} />
        <Route path='/games' element={<Games />} />
        <Route path='/login' element={<Login />} />
      </Routes>
    </>
  );
}

