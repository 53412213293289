import React from 'react';
import Navbar from './Navbar';
import { useParams } from 'react-router-dom';
import '../css/style.css';
import { useState, useEffect } from 'react';
import FeaturedCategories from './FeaturedCategories';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';

const CategoryBasedGame = () => {
    const { categoryid } = useParams();
    const { categoryname } = useParams();
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        fetch(`http://api.fitsgames.com/api/games/category/${categoryid}`)
            .then(response => response.json())
            .then(data => setCategories(data)) // Display only the first 4 categories
            .catch(error => console.error('Error fetching categories:', error));
    }, []);

    const handleLetsPlayClick = (path) => {
        if (sessionStorage.getItem('phoneNumber')) {
            window.location.href=path;
        } else {
            navigate('/login');
        }
    }
    
    return (
        <>
            <Navbar />
            <div className="hero-wrap js-fullheight" style={{ backgroundImage: 'url(/images/banner-1-fits.jpg)', height: 600 }}>
                <div className="overlay"></div>
                <div className="container">
                    <div className="row no-gutters slider-text js-fullheight" data-scrollax-parent="true" style={{ height: "100vh", display: "flex", alignItems: "center" }}>
                        <h1 className="mb-4" data-scrollax="properties: { translateY: '70%', opacity: 1.6 }">
                            {/* <strong>Explore<br /></strong> our amazing content */}
                        </h1>
                        <p data-scrollax="properties: { translateY: '30%', opacity: 1.6 }" style={{ textAlign: 'center', marginTop: '70px' }}>
                            <h1 className="mb-4" data-scrollax="properties: { translateY: '70%', opacity: 1.6 }"><strong>Explore<br />{categoryname}</strong><br /><strong>Games!</strong></h1>
                        </p>
                        <p>
                            {/* Or browse our games by categories */}
                        </p>
                    </div>
                </div>
            </div >

            <section className="ftco-section ftco-destination">
                <div className="container">
                    <span className="subheading">Featured</span>
                    <h2 className="mb-4"><strong>Games</strong> List</h2>
                    <div className="row">
                        {categories.map((category, index) => (
                            <div className="col-md-3" key={category.id}>
                                <div className="item">
                                    <div className="destination">
                                        <a href="#" className="img d-flex justify-content-center align-items-center"
                                            style={{ backgroundImage: `url(${category.thumbnail ? category.thumbnail : '/images/banner-1-fits.jpg'})`, height: '300px', backgroundSize: 'cover' }}>
                                            {/* <div className="icon d-flex justify-content-center align-items-center">
                                                <span className="icon-search2"></span>
                                            </div> */}
                                        </a>
                                        <div class="text p-3">

                                            <h3 style={{ height: '3em' }}>{category.title}</h3>
                                            <div class="d-flex">
                                                <hr />
                                                <p className="truncate">{category.description}</p>
                                            </div>

                                            <hr></hr>
                                            <p class="bottom-area d-flex" >
                                                <span class="ml-auto"><a href='#' style={{ textDecoration: 'none' }} onClick={() => handleLetsPlayClick(category.path)}>Let's Play</a></span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
};

export default CategoryBasedGame;
